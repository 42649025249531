const { ConsoleWriter } = require("istanbul-lib-report");

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
const _RTF = new Intl.RelativeTimeFormat("en", {
    localeMatcher: "best fit",
    numeric: "auto", // This can be set to auto if you want things like "yesterday" and "tomorrow"
    style: "long"
});

class SearchResults {
    constructor(search, results, count) {
        this.search     = search;
        this.total      = count;
        this.activities = this.processResults(results);
    }

    validCount() {
        if (isNaN(this.total)) {
            // Quietly refuse to display a results count if the response from the 
            // api endpoint was invalid
            console.error(this.total + " is not a number");
            return false;
        }
        return true;
    }

    formattedTotal() {
        return this.total.toLocaleString();
    }

    resultCountMessage(include_period) {
        let message = this.formattedTotal() + " ";
        message += this.total != 1 ? "results" : "result";
        if (this.search.organisation) {
            message += " from <a href=\""+ this.search.organisation.url +"\">" + this.search.organisation.name + "</a>";
        }
        if (include_period) {
            message += ".";
        }
        return message;
    }

    currentPage() {
        return this.search.page;
    }

    totalPages() {
        return Math.ceil(this.total / this.search.per_page);
    }

    onFirstPage() {
        return this.search.page == 1;
    }

    previousPageClass() {
        let klass = "agg-pagination-widget-previous";
        if (this.search.totalPages <= 1 || this.onFirstPage() ) {
            klass += " text-muted";
        }
        return klass;
    }
    
    onLastPage() {
        return this.currentPage() == this.totalPages();
    }

    nextPageClass() {
        let klass = "agg-pagination-widget-next";
        if (this.search.totalPages <= 1 || this.onLastPage() ) {
            klass += " text-muted";
        }
        return klass;
    }

    processResults(results_data) {
        if (!Array.isArray(results_data)) {
            throw "Results data is not an array";
        }
        let results = [];
        for (let idx in results_data) {
            results.push(new SearchResult(results_data[idx]));
        }
        return results;
    }
}

class SearchResult {
    constructor(result) {
        this.source = result[0];
        this.status = result[1];
        if (result[2]) {
            this.start = new Date(result[2]);
            this.start.setHours(0,0,0,0);
        } else {
            this.start = null;
        }
        if (result[3]) {
            this.end    = new Date(result[3]);
            this.end.setHours(0,0,0,0);
        } else {
            this.end = null;
        }
        this.title  = result[4];
        this.url    = result[5];
        this.precis = result[6];
        this.slug   = result[7];
    }

    friendlyTimeDelta(target_date) {
        if (!target_date) {
            console.log(this);
            return "";
        }
        //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat
        let now = new Date();
        let utc_today = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
        let utc_date  = Date.UTC(target_date.getFullYear(), target_date.getMonth(), target_date.getDate());
        if (now.toDateString() == target_date.toDateString()) {
            return "today";
        }

        let days = Math.ceil((utc_date - utc_today) / _MS_PER_DAY);
        return _RTF.format(days, "day");
    }

    dateDisplay(status_filter) {
        let date_string = "";
        let date_to_show = this.start;
        // Activities with no start or end date are considered to be open
        // return a string saying as much.
        if (!this.start || !this.end) {
            if (!this.start && !this.end) {
                // No start OR end date - perma-open
                return "Open";
            } else if (!this.end) {
                // Start but no end... "Opened xxx days ago"
                return "Opened" + " " + this.friendlyTimeDelta(this.start);
            } else if (!this.start) {
                // End but no start... "Closes in xxx days"
                return "Closes" + " " + this.friendlyTimeDelta(this.end);
            } else {
                // Something wicked this way walks
                return "";
            }
        }
        let now = new Date();
        now.setHours(0,0,0,0);
        if (status_filter == "newest_update" || status_filter == "oldest_update") {
            if (this.start <= now && this.end > now) {
                date_string += "Opened";
            } else if (this.end <= now) {
                date_string += "Closed";
                date_to_show = this.end;
            } else if (this.start > now) {
                date_string += "Opens";
            }
        } else if (status_filter == "most_recently_opened") {
            date_string += "Opened";
        } else if (status_filter == "closing_soonest") {
            if (this.end <= now) {
                date_string += "Closed";
            } else {
                date_string += "Closes";
            }
            date_to_show = this.end;
        }
        return date_string + " " + this.friendlyTimeDelta(date_to_show);
    }
}


module.exports = {
    SearchResults, SearchResult,
}

